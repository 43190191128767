/*
 * Copyright 2015, Yahoo Inc.
 * Copyrights licensed under the New BSD License.
 * See the accompanying LICENSE file for terms.
 */
import { __extends, __rest, __spreadArrays } from "tslib";
import * as React from 'react';
import { Context } from './injectIntl';
import { invariantIntlContext } from '../utils';
import * as shallowEquals_ from 'shallow-equal/objects';
var shallowEquals = shallowEquals_.default || shallowEquals_;
var FormattedMessage = /** @class */ (function (_super) {
    __extends(FormattedMessage, _super);
    function FormattedMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormattedMessage.prototype.shouldComponentUpdate = function (nextProps) {
        var _a = this.props, values = _a.values, otherProps = __rest(_a, ["values"]);
        var nextValues = nextProps.values, nextOtherProps = __rest(nextProps, ["values"]);
        return (!shallowEquals(nextValues, values) ||
            !shallowEquals(otherProps, nextOtherProps));
    };
    FormattedMessage.prototype.render = function () {
        var _this = this;
        return (React.createElement(Context.Consumer, null, function (intl) {
            invariantIntlContext(intl);
            var formatMessage = intl.formatMessage, _a = intl.textComponent, Text = _a === void 0 ? React.Fragment : _a;
            var _b = _this.props, id = _b.id, description = _b.description, defaultMessage = _b.defaultMessage, values = _b.values, children = _b.children, _c = _b.tagName, Component = _c === void 0 ? Text : _c, ignoreTag = _b.ignoreTag;
            var descriptor = { id: id, description: description, defaultMessage: defaultMessage };
            var nodes = formatMessage(descriptor, values, {
                ignoreTag: ignoreTag,
            });
            if (!Array.isArray(nodes)) {
                nodes = [nodes];
            }
            if (typeof children === 'function') {
                return children(nodes);
            }
            if (Component) {
                // Needs to use `createElement()` instead of JSX, otherwise React will
                // warn about a missing `key` prop with rich-text message formatting.
                return React.createElement.apply(React, __spreadArrays([Component, null], nodes));
            }
            return nodes;
        }));
    };
    FormattedMessage.displayName = 'FormattedMessage';
    return FormattedMessage;
}(React.Component));
export default FormattedMessage;
